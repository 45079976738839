import request from '/src/utils/request';
import fileRequest from '/src/utils/fileRequest';

// 会员导出
export function ApiVipExport(query) {
    return request({
        url: '/export/export',
        method: 'post',
        data: query,
    });
}

// 查询用户导出记录
export function ApiExportGetExportRecord(query) {
    return request({
        url: '/export/getExportRecord',
        method: 'get',
        params: query,
    });
}

// 获取剩余导出次数
export function ApiExportGetExportCount(query) {
    return request({
        url: '/export/getExportCount',
        method: 'get',
        params: query,
    });
}

// 获取会员导出配置
export function ApiExportGetExportConfig(query) {
    return request({
        url: '/export/getExportConfig',
        method: 'get',
        params: query,
    });
}

// 通过订单编号导出excel
export function ApiExportFileByOrderId(query, fileName) {
    return fileRequest({
        url: '/export/exportByOrderId',
        method: 'post',
        data: query,
        fileName,
    });
}

// 导出城市数据
export function ApiExportCitys(fileName) {
    return fileRequest({
        url: '/common/exportCitys',
        method: 'get',
        fileName,
    });
}

// 导出行业数据
export function ApiExportIndustry(fileName) {
    return fileRequest({
        url: '/common/exportIndustry',
        method: 'get',
        fileName,
    });
}

// 下载
export function ApiDownloadReport(query = {}) {
    return request({
        url: '/search/downloadReport',
        method: 'post',
        data: query,
    });
}

// 下载
export function ApiDownloadReportBak(query = {}) {
    return request({
        url: '/search/downloadReportBak',
        method: 'post',
        data: query,
    });
}
